import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { Test_Screen } from "../screens/test/test";
import { Model_Training_Form_Screen } from "../screens/model_training_form/model_training_form";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { Profil_Screen } from "../screens/profil/profil";
import { Signup_Screen } from "../screens/signup/signup";
import { Requirement_Form_Screen } from "../screens/requirement_form/requirement_form";
import { Requirement_CSV_Mapping_Form_Screen } from "../screens/requirement_csv_mapping_form/requirement_csv_mapping_form";
import { Company_Form_Screen } from "../screens/company_form/company_form";
import { Prediction_List_Screen } from "../screens/prediction_list/prediction_list";
import { Model_Training_List_Screen } from "../screens/model_training_list/model_training_list";
import { SiskonMaster_Screen } from "../screens/siskonmaster/siskonmaster";
import { Project_Copy_Form_Screen } from "../screens/project_copy_form/project_copy_form";
import { Project_Form_Screen } from "../screens/project_form/project_form";
import { Model_Form_Screen } from "../screens/model_form/model_form";
import { Requirement_List_Screen } from "../screens/requirement_list/requirement_list";
import { Prediction_Form_Screen } from "../screens/prediction_form/prediction_form";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { UserList_Screen } from "../screens/userlist/userlist";
import { Requirement_List_Backup_Screen } from "../screens/requirement_list_backup/requirement_list_backup";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { UserListFront_Screen } from "../screens/userlistfront/userlistfront";
import { Company_List_Front_Screen } from "../screens/company_list_front/company_list_front";
import { Project_List_Screen } from "../screens/project_list/project_list";
import { Benchmark_Prediction_Form_Screen } from "../screens/benchmark_prediction_form/benchmark_prediction_form";
import { DisabledAccount_Screen } from "../screens/disabledaccount/disabledaccount";
import { Signin_Screen } from "../screens/signin/signin";
import { Benchmark_Prediction_List_Screen } from "../screens/benchmark_prediction_list/benchmark_prediction_list";
import { Benchmark_Project_List_Screen } from "../screens/benchmark_project_list/benchmark_project_list";
import { Benchmark_Prediction_Result_Screen } from "../screens/benchmark_prediction_result/benchmark_prediction_result";
import { IptalPopup_Screen } from "../screens/iptalpopup/iptalpopup";
import { Umit_Screen } from "../screens/umit/umit";
import { Benchmark_Model_List_Screen } from "../screens/benchmark_model_list/benchmark_model_list";
import { Benchmark_Model_Form_Screen } from "../screens/benchmark_model_form/benchmark_model_form";
import { Blank_Screen } from "../screens/blank/blank";
import { UserForm_Screen } from "../screens/userform/userform";
import { LandingPage_Screen } from "../screens/landingpage/landingpage";
import { Prediction_Result_Screen } from "../screens/prediction_result/prediction_result";
import { Company_List_Screen } from "../screens/company_list/company_list";
import { Queue_List_Screen } from "../screens/queue_list/queue_list";
import { Benchmark_Project_Form_Screen } from "../screens/benchmark_project_form/benchmark_project_form";
import { Model_Training_Result_Screen } from "../screens/model_training_result/model_training_result";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={LandingPage_Screen} />
                        <PrivateRoute exact path="/test" component={Test_Screen} />
            <PrivateRoute exact path="/model_training_form" component={Model_Training_Form_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/profil" component={Profil_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/requirement_form" component={Requirement_Form_Screen} />
            <PrivateRoute exact path="/requirement_csv_mapping_form" component={Requirement_CSV_Mapping_Form_Screen} />
            <PrivateRoute exact path="/company_form" component={Company_Form_Screen} />
            <PrivateRoute exact path="/prediction_list" component={Prediction_List_Screen} />
            <PrivateRoute exact path="/model_training_list" component={Model_Training_List_Screen} />
            <PrivateRoute exact path="/project_copy_form" component={Project_Copy_Form_Screen} />
            <PrivateRoute exact path="/project_form" component={Project_Form_Screen} />
            <PrivateRoute exact path="/model_form" component={Model_Form_Screen} />
            <PrivateRoute exact path="/requirement_list" component={Requirement_List_Screen} />
            <PrivateRoute exact path="/prediction_form" component={Prediction_Form_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/userlist" component={UserList_Screen} />
            <PrivateRoute exact path="/requirement_list_backup" component={Requirement_List_Backup_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/userlistfront" component={UserListFront_Screen} />
            <PrivateRoute exact path="/company_list_front" component={Company_List_Front_Screen} />
            <PrivateRoute exact path="/project_list" component={Project_List_Screen} />
            <PrivateRoute exact path="/benchmark_prediction_form" component={Benchmark_Prediction_Form_Screen} />
            <PrivateRoute exact path="/disabledaccount" component={DisabledAccount_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/benchmark_prediction_list" component={Benchmark_Prediction_List_Screen} />
            <PrivateRoute exact path="/benchmark_project_list" component={Benchmark_Project_List_Screen} />
            <PrivateRoute exact path="/benchmark_prediction_result" component={Benchmark_Prediction_Result_Screen} />
            <PrivateRoute exact path="/iptalpopup" component={IptalPopup_Screen} />
            <PrivateRoute exact path="/umit" component={Umit_Screen} />
            <PrivateRoute exact path="/benchmark_model_list" component={Benchmark_Model_List_Screen} />
            <PrivateRoute exact path="/benchmark_model_form" component={Benchmark_Model_Form_Screen} />
            <PrivateRoute exact path="/blank" component={Blank_Screen} />
            <PrivateRoute exact path="/userform" component={UserForm_Screen} />
            <PrivateRoute exact path="/landingpage" component={LandingPage_Screen} />
            <PrivateRoute exact path="/prediction_result" component={Prediction_Result_Screen} />
            <PrivateRoute exact path="/company_list" component={Company_List_Screen} />
            <PrivateRoute exact path="/queue_list" component={Queue_List_Screen} />
            <PrivateRoute exact path="/benchmark_project_form" component={Benchmark_Project_Form_Screen} />
            <PrivateRoute exact path="/model_training_result" component={Model_Training_Result_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
