import { Button, Input, Modal, Select } from "antd";
import classNames from "classnames";
import { saveAs } from "file-saver";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import type { WorkBook } from "xlsx";
import {
  IImportMappedExcelColumnMap,
  IImportMappedExcelColumnMapDefinition,
  ImportMappedExcelStatus
} from "../../../models/dto/excel";
import { ELanguage } from "../../../models/enum/language";
import { hideImportMappedExcelModal } from "../../../redux/modals/actions";
import { AppState } from "../../../redux/root-reducer";
import { translationService } from "../../../services/_singletons";
import ExcelService from "../../../services/excel-service";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import "./import-mapped-excel-runtime.scss";

interface ImportMappedExcelRuntimeProps {
  dsId: string | null;
  tableName: string | null;
  primaryKey: string | null;
  columnMappings: IImportMappedExcelColumnMapDefinition[];
  onClose: () => void;
}

interface ImportMappedExcelRuntimeState {
  file: File | null;
  workbook: WorkBook | null;
  columns: string[];
  sheet: unknown[] | null;
  selectedSheetName: string | null;
  columnMappings: IImportMappedExcelColumnMap[];
  status: "map" | "success" | "partial-success" | "error" | "queued";
  isUploadLoading: boolean;
  errorCount: number;
  successCount: number;
  totalCount: number;
  rowsInserted: number;
  rowsUpdated: number;
  erroredRows: object[];
  rowsWithValidationErrors: object[];
}

class ImportMappedExcelRuntime extends PureComponent<ImportMappedExcelRuntimeProps, ImportMappedExcelRuntimeState> {
  private inputRef = React.createRef<HTMLInputElement>();

  private translationService = translationService;

  constructor(props: ImportMappedExcelRuntimeProps) {
    super(props);
    this.state = {
      file: null,
      workbook: null,
      sheet: null,
      selectedSheetName: null,
      columns: [],
      columnMappings: [],
      status: "map",
      isUploadLoading: false,
      errorCount: 0,
      successCount: 0,
      totalCount: 0,
      rowsInserted: 0,
      rowsUpdated: 0,
      erroredRows: [],
      rowsWithValidationErrors: []
    };
  }

  private get language() {
    const language = localStorage.getItem("ml") as ELanguage;

    if (!language) {
      return ELanguage.EN;
    }

    return language;
  }

  componentDidMount() {
    // if (_.isEmpty(this.props.dsId) || _.isEmpty(this.props.tableName) || _.isEmpty(this.props.primaryKey)) {
    //   KMainFunctions.displayErrorNotification("Import Mapped Excel not initialized correctly");
    //   this.props.onClose();
    //   return;
    // }

    this.setState({
      columnMappings: _.cloneDeep(
        this.getSortedColumnMappings(this.props.columnMappings.filter((columnMapping) => columnMapping.isEnabled) ?? [])
      )
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  onClickUpload = async () => {
    if (!this.inputRef.current) return;
    this.inputRef.current.click();
  };

  onClickNext = async () => {
    try {
      if (this.getIsNextDisabled()) return;
      this.setState({ isUploadLoading: true });

      const primaryKeyMapping: IImportMappedExcelColumnMap = this.state.columnMappings.find(
        (columnMapping) => columnMapping.source === this.props.primaryKey
      );

      if (primaryKeyMapping?.source !== "Id" && !primaryKeyMapping?.target) {
        KMainFunctions.displayWarningNotification(
          this.translationService.translate("import_mapped_excel_runtime.primary_key_warning")
        );
        return;
      }

      const response = await ExcelService.ImportMappedExcel({
        datasourceId: this.props.dsId,
        tableName: this.props.tableName,
        primaryKey: this.props.primaryKey,
        mappedColumns: this.state.columnMappings,
        columns: this.state.columns,
        data: this.state.sheet as object[],
        language: this.language
      });

      if (response.status === 200) {
        let status: "map" | "success" | "partial-success" | "error" | "queued" = "map";

        if (response.data.status === ImportMappedExcelStatus.Success) {
          status = "success";
        }

        if (response.data.status === ImportMappedExcelStatus.PartialSuccess) {
          status = "partial-success";
        }

        if (response.data.status === ImportMappedExcelStatus.Error) {
          status = "error";
        }

        if (response.data.status === ImportMappedExcelStatus.Queued) {
          status = "queued";
        }

        this.setState({
          status,
          errorCount: response.data.errorCount,
          successCount: response.data.successCount,
          totalCount: response.data.totalCount,
          rowsInserted: response.data.rowsInserted,
          rowsUpdated: response.data.rowsUpdated,
          erroredRows: response.data.erroredRows,
          rowsWithValidationErrors: response.data.rowsWithValidationErrors
        });
      }
    } catch (e) {
      KMainFunctions.exceptionHandler(e);
    } finally {
      this.setState({ isUploadLoading: false });
    }
  };

  getIsNextDisabled = () => {
    if (this.state.isUploadLoading) return true;
    if (!this.state.file) return true;
    if (!this.state.workbook) return true;
    if (!this.state.sheet) return true;
    if (!this.state.selectedSheetName) return true;
    if (!this.state.columns) return true;
    if (!this.state.columnMappings) return true;
    if (this.state.columnMappings.length === 0) return true;

    if (
      this.state.columnMappings.some(
        (columnMapping) => columnMapping.target === undefined || columnMapping.target === ""
      )
    ) {
      return true;
    }

    if (
      this.state.columnMappings.some(
        (columnMapping) => columnMapping.target === "<use-default-value>" && _.isEmpty(columnMapping.defaultValue)
      )
    ) {
      return true;
    }

    return false;
  };

  getIsNextLoading = () => {
    return this.state.isUploadLoading;
  };

  onChangeUploadedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    // Since this is a modal that will be existing all apps whether the app needs
    // xlsx or not, we need to dynamically import it.
    const XLSX = await import(/* webpackChunkName: "xlsx" */ "xlsx");

    reader.onload = async (e) => {
      const data: Uint8Array = new Uint8Array(e.target.result as ArrayBufferLike);
      const workbook: WorkBook = XLSX.read(data, { type: "array", cellDates: true, dateNF: 'yyyy-mm-dd"T"hh:mm:ss' });
      this.setState(
        {
          file,
          workbook,
          sheet: null,
          selectedSheetName: null,
          columns: [],
          errorCount: 0,
          successCount: 0,
          totalCount: 0,
          erroredRows: [],
          rowsWithValidationErrors: [],
          status: "map"
        },
        () => {
          const sheetNames: string[] =
            workbook.SheetNames?.filter((sheetName) => sheetName !== "Errors" && sheetName !== "Hatalar") ?? [];
          if (sheetNames.length === 1) {
            this.onChangeSheet(workbook.SheetNames[0]);
          }
        }
      );
    };

    reader.readAsArrayBuffer(file);
  };

  onChangeSheet = async (selectedSheetName: string) => {
    const XLSX = await import(/* webpackChunkName: "xlsx" */ "xlsx");

    const sheet = XLSX.utils.sheet_to_json(this.state.workbook.Sheets[selectedSheetName], {
      header: 1,
      raw: false,
      rawNumbers: false,
      dateNF: 'yyyy-mm-dd"T"hh:mm:ss' // Set date format
    });

    // Names in the first row
    const columns = sheet.shift() as string[] | undefined;

    if (columns === undefined || columns.length === 0) {
      KMainFunctions.displayWarningNotification(`No columns found in sheet "${selectedSheetName}"`);
      return;
    }

    const columnMappings: IImportMappedExcelColumnMap[] = this.state.columnMappings.map((columnMapping) => {
      const targetColumn = columns.find((column) => column === columnMapping.alias || column === columnMapping.source);

      if (columnMapping.target && columns.includes(columnMapping.target)) {
        // If the target column is already set, we don't need to do anything
      } else if (
        targetColumn?.toLowerCase() === columnMapping.alias?.toLowerCase() ||
        targetColumn?.toLowerCase() === columnMapping.source?.toLowerCase()
      ) {
        columnMapping.target = targetColumn;
      } else {
        columnMapping.target = null;
      }

      return columnMapping;
    });

    this.setState({ sheet, selectedSheetName, columns, columnMappings });
  };

  renderMapColumnsRow = (columnMapping: IImportMappedExcelColumnMap) => {
    return (
      <tr key={columnMapping.alias}>
        <td className="map-the-sheet-columns--table--alias-column">
          <span>{columnMapping.alias}</span>&nbsp;
          {this.props.primaryKey === columnMapping.source && <span>(Primary Key)</span>}
        </td>
        <td className="map-the-sheet-columns--table--target-column">{this.renderColumnToMap(columnMapping)}</td>
        <td className="map-the-sheet-columns--table--default-value-column">
          <Input
            value={columnMapping.defaultValue}
            onChange={(e) => this.onChangeDefaultValue(columnMapping, e.target.value)}
            disabled={!columnMapping.target || this.props.primaryKey === columnMapping.source}
            placeholder={this.translationService.translate("common.default_value", this.language)}
            bordered={false}
          />
        </td>
      </tr>
    );
  };

  renderUploadIcon = () => {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_13237_16087)">
          <path
            d="M2 8.5V9.5C2 9.76522 2.10536 10.0196 2.29289 10.2071C2.48043 10.3946 2.73478 10.5 3 10.5H9C9.26522 10.5 9.51957 10.3946 9.70711 10.2071C9.89464 10.0196 10 9.76522 10 9.5V8.5"
            stroke="#606F85"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.5 4.5L6 2L8.5 4.5"
            stroke="#606F85"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M6 2V8" stroke="#606F85" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_13237_16087">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  onChangeColumnToMap = (columnMapping: IImportMappedExcelColumnMap, column?: string) => {
    if (column === null) {
      columnMapping.target = null;
      columnMapping.defaultValue = null;
    } else {
      columnMapping.target = column;
    }

    this.setState({ columnMappings: _.cloneDeep(this.state.columnMappings) });
  };

  onChangeDefaultValue = (columnMapping: IImportMappedExcelColumnMap, value: string) => {
    columnMapping.defaultValue = value;
    this.setState({ columnMappings: _.cloneDeep(this.state.columnMappings) });
  };

  renderColumnToMap = (columnMapping: IImportMappedExcelColumnMap) => {
    return (
      <Select
        value={columnMapping.target}
        onChange={(value) => this.onChangeColumnToMap(columnMapping, value)}
        placeholder={this.translationService.translate("import_mapped_excel_runtime.select_column", this.language)}
        bordered={false}
      >
        <Select.OptGroup
          label={this.translationService.translate("import_mapped_excel_runtime.default_values", this.language)}
        >
          <Select.Option value={null}>
            {this.translationService.translate("import_mapped_excel_runtime.skip", this.language)}
          </Select.Option>
          <Select.Option value="<use-default-value>">
            {this.translationService.translate("import_mapped_excel_runtime.use_default_value", this.language)}
          </Select.Option>
        </Select.OptGroup>
        <Select.OptGroup
          label={this.translationService.translate("import_mapped_excel_runtime.excel_columns", this.language)}
        >
          {this.state.columns.map((column: string) => (
            <Select.Option key={column} value={column}>
              {column}
            </Select.Option>
          ))}
        </Select.OptGroup>
      </Select>
    );
  };

  renderMainContent = () => {
    return (
      <>
        <div className="upload-excel-file--title">
          {this.translationService.translate("import_mapped_excel_runtime.upload_excel_file.title", this.language)}
        </div>
        <div className="upload-excel-file--description">
          {this.translationService.translate(
            "import_mapped_excel_runtime.upload_excel_file.description",
            this.language
          )}
        </div>
        <input
          ref={this.inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={this.onChangeUploadedFile}
          accept=".xls,.xlsx"
          multiple={false}
        />
        <div className="upload-excel-file--selections">
          {this.state.file === null ? (
            <button
              className="upload-excel-file--button"
              disabled={this.state.isUploadLoading}
              onClick={this.onClickUpload}
            >
              {/* Upload Excel File */}
              {this.translationService.translate("import_mapped_excel_runtime.upload_excel_file.button", this.language)}
              {this.renderUploadIcon()}
            </button>
          ) : (
            <button className="upload-excel-file--reupload-button" onClick={this.onClickUpload}>
              {this.state.file.name}
              {this.renderUploadIcon()}
            </button>
          )}
          {this.state.workbook ? (
            <Select
              className="upload-excel-file--sheet-select"
              placeholder={this.translationService.translate(
                "import_mapped_excel_runtime.upload_excel_file.select_sheet",
                this.language
              )}
              disabled={this.state.isUploadLoading}
              value={this.state.selectedSheetName}
              onChange={this.onChangeSheet}
            >
              {this.state.workbook.SheetNames.filter(
                (sheetName) => sheetName !== "Errors" && sheetName !== "Hatalar"
              ).map((sheet) => (
                <Select.Option key={sheet} value={sheet}>
                  {sheet}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <div></div>
          )}
        </div>
        <div className="map-the-sheet-columns--title">
          {this.translationService.translate("import_mapped_excel_runtime.map_the_sheet_columns.title", this.language)}
        </div>
        <table
          className={classNames("map-the-sheet-columns--table", {
            "map-the-sheet-columns--table--has-data": !!this.state.sheet
          })}
        >
          <thead>
            {/* <th>Source Column</th> */}
            {/* <th>Column to Map</th> */}
            {/* <th>Default Value</th> */}
            <th>
              {this.translationService.translate(
                "import_mapped_excel_runtime.map_the_sheet_columns.source_column",
                this.language
              )}
            </th>
            <th>
              {this.translationService.translate(
                "import_mapped_excel_runtime.map_the_sheet_columns.column_to_map",
                this.language
              )}
            </th>
            <th>
              {this.translationService.translate(
                "import_mapped_excel_runtime.map_the_sheet_columns.default_value",
                this.language
              )}
            </th>
          </thead>
          <tbody>
            {this.state.sheet &&
              this.state.columnMappings.map((columnMapping: IImportMappedExcelColumnMap) =>
                this.renderMapColumnsRow(columnMapping)
              )}
          </tbody>
        </table>
        {!this.state.sheet && (
          <div className="map-the-sheet-columns--table-no-sheet-is-selected">
            {this.translationService.translate(
              "import_mapped_excel_runtime.map_the_sheet_columns.no_sheet_is_selected",
              this.language
            )}
          </div>
        )}
      </>
    );
  };

  renderSuccessResultIcon = () => {
    return (
      <svg
        className="result-icon"
        width="64"
        height="64"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_12009_190548)">
          <rect x="12" y="8" width="64" height="64" rx="8" fill="#E8F2E6" shape-rendering="crispEdges" />
          <rect x="12.5" y="8.5" width="63" height="63" rx="7.5" stroke="#C5E9BF" shape-rendering="crispEdges" />
          <g clip-path="url(#clip0_12009_190548)">
            <path
              d="M32 40C32 41.5759 32.3104 43.1363 32.9134 44.5922C33.5165 46.0481 34.4004 47.371 35.5147 48.4853C36.629 49.5996 37.9519 50.4835 39.4078 51.0866C40.8637 51.6896 42.4241 52 44 52C45.5759 52 47.1363 51.6896 48.5922 51.0866C50.0481 50.4835 51.371 49.5996 52.4853 48.4853C53.5996 47.371 54.4835 46.0481 55.0866 44.5922C55.6896 43.1363 56 41.5759 56 40C56 38.4241 55.6896 36.8637 55.0866 35.4078C54.4835 33.9519 53.5996 32.629 52.4853 31.5147C51.371 30.4004 50.0481 29.5165 48.5922 28.9134C47.1363 28.3104 45.5759 28 44 28C42.4241 28 40.8637 28.3104 39.4078 28.9134C37.9519 29.5165 36.629 30.4004 35.5147 31.5147C34.4004 32.629 33.5165 33.9519 32.9134 35.4078C32.3104 36.8637 32 38.4241 32 40Z"
              stroke="#213C1C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M40 39.9999L42.6667 42.6666L48 37.3333"
              stroke="#213C1C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_12009_190548"
            x="0"
            y="0"
            width="88"
            height="88"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12009_190548" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12009_190548" result="shape" />
          </filter>
          <clipPath id="clip0_12009_190548">
            <rect width="32" height="32" fill="white" transform="translate(28 24)" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  renderPartialSuccessResultIcon = () => {
    return (
      <svg
        className="result-icon"
        width="64"
        height="64"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_12009_190575)">
          <rect x="12" y="8" width="64" height="64" rx="8" fill="#FFF5D9" shape-rendering="crispEdges" />
          <rect x="12.5" y="8.5" width="63" height="63" rx="7.5" stroke="#FFEBB2" shape-rendering="crispEdges" />
          <g clip-path="url(#clip0_12009_190575)">
            <path
              d="M39.4143 28.9192C37.9584 29.5218 36.6355 30.4053 35.521 31.5192"
              stroke="#5C4913"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.92 35.4141C32.3154 36.8678 32.0028 38.4263 32 40.0007"
              stroke="#5C4913"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.9219 44.5859C33.5245 46.0418 34.408 47.3648 35.5219 48.4793"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M39.4116 51.0808C40.8654 51.6854 42.4238 51.998 43.9983 52.0008"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M48.5884 51.0792C50.0443 50.4766 51.3672 49.5931 52.4817 48.4792"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M55.0781 44.5867C55.6827 43.1329 55.9953 41.5744 55.9981 40"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M55.079 35.4141C54.4764 33.9582 53.5929 32.6352 52.479 31.5208"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M48.5867 28.92C47.1329 28.3154 45.5744 28.0028 44 28"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M40 39.9999L42.6667 42.6666L48 37.3333"
              stroke="#5C4913"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_12009_190575"
            x="0"
            y="0"
            width="88"
            height="88"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12009_190575" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12009_190575" result="shape" />
          </filter>
          <clipPath id="clip0_12009_190575">
            <rect width="32" height="32" fill="white" transform="translate(28 24)" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  renderQueuedResultIcon = () => {
    return (
      <svg
        className="result-icon"
        width="64"
        height="64"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_12009_190604)">
          <rect x="12" y="8" width="64" height="64" rx="8" fill="#F1ECFF" shape-rendering="crispEdges" />
          <rect x="12.5" y="8.5" width="63" height="63" rx="7.5" stroke="#D5C6FF" shape-rendering="crispEdges" />
          <g clip-path="url(#clip0_12009_190604)">
            <path
              d="M30.6665 40H33.3332"
              stroke="#331A75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.6665 40H57.3332"
              stroke="#331A75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M33.3335 39.9999C33.3335 40.7072 33.6144 41.3854 34.1145 41.8855C34.6146 42.3856 35.2929 42.6666 36.0002 42.6666C36.7074 42.6666 37.3857 42.3856 37.8858 41.8855C38.3859 41.3854 38.6668 40.7072 38.6668 39.9999C38.6668 39.2927 38.3859 38.6144 37.8858 38.1143C37.3857 37.6142 36.7074 37.3333 36.0002 37.3333C35.2929 37.3333 34.6146 37.6142 34.1145 38.1143C33.6144 38.6144 33.3335 39.2927 33.3335 39.9999Z"
              stroke="#331A75"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M49.3335 39.9999C49.3335 40.7072 49.6144 41.3854 50.1145 41.8855C50.6146 42.3856 51.2929 42.6666 52.0002 42.6666C52.7074 42.6666 53.3857 42.3856 53.8858 41.8855C54.3859 41.3854 54.6668 40.7072 54.6668 39.9999C54.6668 39.2927 54.3859 38.6144 53.8858 38.1143C53.3857 37.6142 52.7074 37.3333 52.0002 37.3333C51.2929 37.3333 50.6146 37.6142 50.1145 38.1143C49.6144 38.6144 49.3335 39.2927 49.3335 39.9999Z"
              stroke="#331A75"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M38.6665 40H49.3332"
              stroke="#331A75"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_12009_190604"
            x="0"
            y="0"
            width="88"
            height="88"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12009_190604" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12009_190604" result="shape" />
          </filter>
          <clipPath id="clip0_12009_190604">
            <rect width="32" height="32" fill="white" transform="translate(28 24)" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  renderErrorResultIcon = () => {
    return (
      <svg
        className="result-icon"
        width="64"
        height="64"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_12009_190588)">
          <rect x="12" y="8" width="64" height="64" rx="8" fill="#F1ECFF" shape-rendering="crispEdges" />
          <rect x="12.5" y="8.5" width="63" height="63" rx="7.5" stroke="#D5C6FF" shape-rendering="crispEdges" />
          <g clip-path="url(#clip0_12009_190588)">
            <path
              d="M30.6665 40H33.3332"
              stroke="#331A75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.6665 40H57.3332"
              stroke="#331A75"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M33.3335 39.9999C33.3335 40.7072 33.6144 41.3854 34.1145 41.8855C34.6146 42.3856 35.2929 42.6666 36.0002 42.6666C36.7074 42.6666 37.3857 42.3856 37.8858 41.8855C38.3859 41.3854 38.6668 40.7072 38.6668 39.9999C38.6668 39.2927 38.3859 38.6144 37.8858 38.1143C37.3857 37.6142 36.7074 37.3333 36.0002 37.3333C35.2929 37.3333 34.6146 37.6142 34.1145 38.1143C33.6144 38.6144 33.3335 39.2927 33.3335 39.9999Z"
              stroke="#331A75"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M49.3335 39.9999C49.3335 40.7072 49.6144 41.3854 50.1145 41.8855C50.6146 42.3856 51.2929 42.6666 52.0002 42.6666C52.7074 42.6666 53.3857 42.3856 53.8858 41.8855C54.3859 41.3854 54.6668 40.7072 54.6668 39.9999C54.6668 39.2927 54.3859 38.6144 53.8858 38.1143C53.3857 37.6142 52.7074 37.3333 52.0002 37.3333C51.2929 37.3333 50.6146 37.6142 50.1145 38.1143C49.6144 38.6144 49.3335 39.2927 49.3335 39.9999Z"
              stroke="#331A75"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M38.6665 40H49.3332"
              stroke="#331A75"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_12009_190588"
            x="0"
            y="0"
            width="88"
            height="88"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12009_190588" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12009_190588" result="shape" />
          </filter>
          <clipPath id="clip0_12009_190588">
            <rect width="32" height="32" fill="white" transform="translate(28 24)" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  renderSuccessContent = () => {
    return (
      <div className="import-mapped-excel-runtime-result">
        {this.renderSuccessResultIcon()}
        <div className="result-title">
          {this.translationService.translate("import_mapped_excel_runtime.result.success.title", this.language)}
        </div>
        <div className="result-description">
          {/* Data has been successfully imported into the system. */}
          {this.translationService.translate("import_mapped_excel_runtime.result.success.description", this.language)}
        </div>
        <table className="result-table">
          <thead>
            <tr>
              <th>
                {this.translationService.translate(
                  "import_mapped_excel_runtime.result.success.rows_inserted",
                  this.language
                )}
              </th>
              <th>
                {this.translationService.translate(
                  "import_mapped_excel_runtime.result.success.rows_updated",
                  this.language
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.rowsInserted}</td>
              <td>{this.state.rowsUpdated}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderErrorContent = () => {
    return (
      <div className="import-mapped-excel-runtime-result">
        {this.renderErrorResultIcon()}
        <div className="result-title">
          {this.translationService.translate("import_mapped_excel_runtime.result.error.title", this.language)}
        </div>
        <div className="result-description">
          {this.translationService.translate("import_mapped_excel_runtime.result.error.description", this.language)}
        </div>
        <input
          ref={this.inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={this.onChangeUploadedFile}
          accept=".xls,.xlsx"
          multiple={false}
        />
        <button className="result-retry-upload-button" onClick={this.onClickUpload}>
          {this.translationService.translate("common.upload", this.language)}
          {this.renderUploadIcon()}
        </button>
        <table className="result-table">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>
                {this.translationService.translate("import_mapped_excel_runtime.result.error.error", this.language)}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="error-column">
                <div className="error-column-title">
                  {this.state.errorCount}&nbsp;
                  {this.translationService.translate("import_mapped_excel_runtime.result.error.errors", this.language)}
                </div>
                <button className="error-column-download" onClick={this.onClickDownloadErrors}>
                  {this.translationService.translate(
                    "import_mapped_excel_runtime.result.error.download",
                    this.language
                  )}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="result-tip">
          {this.translationService.translate("import_mapped_excel_runtime.result.tip", this.language)}
        </div>
      </div>
    );
  };

  renderPartialSuccessContent = () => {
    return (
      <div className="import-mapped-excel-runtime-result">
        {this.renderPartialSuccessResultIcon()}
        <div className="result-title">
          {this.translationService.translate("import_mapped_excel_runtime.result.partial_success.title", this.language)}
        </div>
        <div className="result-description">
          {/* Some of the data has been successfully imported into the system. */}
          {this.translationService.translate(
            "import_mapped_excel_runtime.result.partial_success.description",
            this.language
          )}
        </div>
        <input
          ref={this.inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={this.onChangeUploadedFile}
          accept=".xls,.xlsx"
          multiple={false}
        />
        <button className="result-retry-upload-button" onClick={this.onClickUpload}>
          {this.translationService.translate("common.upload", this.language)}
          {this.renderUploadIcon()}
        </button>
        <table className="result-table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>
                {this.translationService.translate(
                  "import_mapped_excel_runtime.result.partial_success.rows_inserted",
                  this.language
                )}
              </th>
              <th style={{ width: "30%" }}>
                {this.translationService.translate(
                  "import_mapped_excel_runtime.result.partial_success.rows_updated",
                  this.language
                )}
              </th>
              <th style={{ width: "40%" }}>
                {this.translationService.translate(
                  "import_mapped_excel_runtime.result.partial_success.error",
                  this.language
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.rowsInserted}</td>
              <td>{this.state.rowsUpdated}</td>
              <td className="error-column">
                <div className="error-column-title">
                  {this.state.errorCount}&nbsp;
                  {this.translationService.translate(
                    "import_mapped_excel_runtime.result.partial_success.errors",
                    this.language
                  )}
                </div>
                <button className="error-column-download" onClick={this.onClickDownloadErrors}>
                  {this.translationService.translate(
                    "import_mapped_excel_runtime.result.partial_success.download",
                    this.language
                  )}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="result-tip">
          {this.translationService.translate("import_mapped_excel_runtime.result.tip", this.language)}
        </div>
      </div>
    );
  };

  onClickDownloadErrors = async () => {
    // Since this is a modal that will be existing all apps whether the app needs
    // xlsx or not, we need to dynamically import it.
    const XLSX = await import(/* webpackChunkName: "xlsx" */ "xlsx");

    // New Workbook
    const workbook = _.cloneDeep(this.state.workbook);
    // Remove sheets other than the selected sheet
    workbook.Sheets = _.pick(workbook.Sheets, this.state.selectedSheetName);

    let worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[this.state.selectedSheetName], {
      header: 1,
      raw: true,
      rawNumbers: false
    });
    const columns = worksheet.shift() as string[] | undefined;

    const errors: object[] = [...(this.state.rowsWithValidationErrors ?? []), ...(this.state.erroredRows ?? [])];

    let primaryKeyIndex = columns?.findIndex((column) => {
      const columnMapping = this.state.columnMappings.find((columnMapping) => columnMapping.target === column);
      return column === columnMapping?.target;
    });

    if (this.props.primaryKey === "Id" && primaryKeyIndex === -1) {
      columns.unshift("Id");
      primaryKeyIndex = 0;
    }

    if (primaryKeyIndex === -1) {
      // We can safely return here because the primary key is required.
      // If the primary key is not found, the user will be notified in the UI prior to importing the data.
      throw new Error("Primary key not found");
    }

    let primaryKeySourceName = this.state.columnMappings.find(
      (columnMapping) => columnMapping.target === columns[primaryKeyIndex]
    )?.source;

    if (this.props.primaryKey === "Id" && primaryKeySourceName === undefined) {
      primaryKeySourceName = "Id";
    }

    if (!primaryKeySourceName) {
      // We can safely return here because the primary key is required.
      // If the primary key is not found, the user will be notified in the UI prior to importing the data.
      throw new Error("Primary key source not found");
    }

    let primaryKeyTargetName = this.state.columnMappings.find(
      (columnMapping) => columnMapping.target === columns[primaryKeyIndex]
    )?.target;

    if (this.props.primaryKey === "Id" && primaryKeyTargetName === undefined) {
      primaryKeyTargetName = "Id";
    }

    if (!primaryKeyTargetName) {
      // We can safely return here because the primary key is required.
      // If the primary key is not found, the user will be notified in the UI prior to importing the data.
      throw new Error("Primary key target not found");
    }

    worksheet = worksheet
      .filter((row) => {
        const error = errors.find((e) => {
          // eslint-disable-next-line eqeqeq
          return e[primaryKeySourceName] == row[primaryKeyIndex];
        });
        return error !== undefined;
      })
      .map((row: object) => Object.values(row));

    const worksheetData = [columns, ...worksheet] as object[][];

    workbook.Sheets[this.state.selectedSheetName] = XLSX.utils.aoa_to_sheet(worksheetData);

    const getErrorMessage = (error: object) => {
      if (_.get(error, "_validationErrors", null)) {
        return _.get(error, "_validationErrors", [])
          .map((error: object) => _.get(error, "errorMessage", ""))
          .join("\n");
      }

      if (_.get(error, "_errorReason", null)) {
        return _.get(error, "_errorReason");
      }

      return "";
    };

    const errorWorkSheetData = [
      [primaryKeyTargetName, this.language === ELanguage.TR ? "Hata" : "Error"],
      ...errors.map((error) => [error[primaryKeySourceName], getErrorMessage(error)])
    ];

    if (workbook.Sheets.Hatalar) {
      delete workbook.Sheets.Hatalar;
      workbook.SheetNames = workbook.SheetNames.filter((sheetName) => sheetName !== "Hatalar");
    }

    if (workbook.Sheets.Errors) {
      delete workbook.Sheets.Errors;
      workbook.SheetNames = workbook.SheetNames.filter((sheetName) => sheetName !== "Errors");
    }

    const errorWorkSheet = XLSX.utils.aoa_to_sheet(errorWorkSheetData);
    workbook.Sheets[this.language === ELanguage.TR ? "Hatalar" : "Errors"] = errorWorkSheet;
    workbook.SheetNames.push(this.language === ELanguage.TR ? "Hatalar" : "Errors");

    // Download the excel file
    const blob = new Blob([XLSX.write(workbook, { bookType: "xlsx", type: "array" })], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    let filename = this.state.file.name;

    if (filename.endsWith(".xlsx")) {
      filename = filename.substring(0, filename.length - 5);
    } else if (filename.endsWith(".xls")) {
      filename = filename.substring(0, filename.length - 4);
    }

    saveAs(blob, `${filename}-${this.language === ELanguage.TR ? "hatalar" : "errors"}.xlsx`);
  };

  renderFooter = () => {
    if (this.state.status === "success" || this.state.status === "partial-success" || this.state.status === "error") {
      return (
        <div className="import-mapped-excel-runtime--footer">
          <Button type="primary" onClick={() => this.props.onClose()}>
            {this.translationService.translate("common.ok", this.language)}
          </Button>
        </div>
      );
    }

    return (
      <div className="import-mapped-excel-runtime--footer">
        <Button
          type="primary"
          disabled={this.getIsNextDisabled()}
          loading={this.getIsNextLoading()}
          onClick={this.onClickNext}
        >
          {this.state.isUploadLoading
            ? this.translationService.translate("common.importing", this.language)
            : this.translationService.translate("common.next", this.language)}
        </Button>
      </div>
    );
  };

  renderTitle = () => {
    let button = (
      /* TODO: Make this a proper button */
      <svg
        onClick={this.onClose}
        style={{ cursor: "pointer" }}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_13220_11229)">
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#606F85"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#606F85"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_13220_11229">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );

    return (
      <div className="import-mapped-excel-runtime--title">
        <div className="title">
          {this.translationService.translate("import_mapped_excel_runtime.title", this.language)}
        </div>
        {button}
      </div>
    );
  };

  renderQueuedContent = () => {
    return (
      <div className="import-mapped-excel-runtime-result">
        {this.renderQueuedResultIcon()}
        <div className="result-title">
          {this.translationService.translate("import_mapped_excel_runtime.result.queued.title", this.language)}
        </div>
        <div className="result-description">
          {this.translationService.translate("import_mapped_excel_runtime.result.queued.description", this.language)}
        </div>
      </div>
    );
  };

  getSortedColumnMappings = (columnMappings: IImportMappedExcelColumnMap[]) => {
    return columnMappings.sort((a, b) => {
      // Primary key goes first
      if (a.source === this.props.primaryKey) return -1;
      if (b.source === this.props.primaryKey) return 1;
      return a.source.localeCompare(b.alias, undefined, { numeric: true }); // Alphanumeric sort
    });
  };

  render() {
    return (
      <Modal
        open={true}
        width={620}
        className="import-mapped-excel-runtime"
        title={this.renderTitle()}
        footer={this.renderFooter()}
      >
        {this.state.status === "map" && this.renderMainContent()}
        {this.state.status === "error" && this.renderErrorContent()}
        {this.state.status === "success" && this.renderSuccessContent()}
        {this.state.status === "partial-success" && this.renderPartialSuccessContent()}
        {this.state.status === "queued" && this.renderQueuedContent()}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    dsId: state.importMappedExcelModalReducer.dsId ?? null,
    tableName: state.importMappedExcelModalReducer.tableName ?? null,
    primaryKey: state.importMappedExcelModalReducer.primaryKey ?? null,
    columnMappings: state.importMappedExcelModalReducer.columnMapping ?? []
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onClose: () => dispatch(hideImportMappedExcelModal())
  };
};

const importMappedExcelRuntime = connect(mapStateToProps, mapDispatchToProps)(ImportMappedExcelRuntime);
export { importMappedExcelRuntime as ImportMappedExcelRuntime };
