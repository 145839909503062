import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

class ServerFilesService {
  public static async DownloadFile(fileId: string, filename): Promise<AxiosResponse<string>> {
    const data = {
      fileId // Update with the actual file name
    };
    try {
      const res = await Axios.post<null, AxiosResponse>(`${backendUrl}/resource/download`, data, {
        responseType: "blob" // important for handling binary data
      });
      KMainFunctions.downloadFile(res.data, filename);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async DeleteFile(fileId: string): Promise<AxiosResponse<string>> {
    const data = {
      fileId // Update with the actual file name
    };
    try {
      const res = await Axios.post<null, AxiosResponse>(`${backendUrl}/resource/delete`, data);
      return res.data;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}

export default ServerFilesService;
