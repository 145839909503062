import { IImportMappedExcelColumnMapDefinition } from "../../models/dto/excel";
import {
  HIDE_IMPORT_MAPPED_EXCEL_MODAL,
  HIDE_IYZICO_DRAWER,
  HIDE_PHOTO_TAKE,
  HIDE_STRIPE_DRAWER,
  ImportMappedExcelModalActionTypes,
  IyzicoDrawerActionTypes,
  PhotoTakeActionTypes,
  SHOW_IMPORT_MAPPED_EXCEL_MODAL,
  SHOW_IYZICO_DRAWER,
  SHOW_PHOTO_TAKE,
  SHOW_STRIPE_DRAWER,
  StripeDrawerActionTypes
} from "./types";

export const hidePhotoTake = (): PhotoTakeActionTypes => {
  return {
    type: HIDE_PHOTO_TAKE,
    meta: {
      params: {}
    }
  };
};

export const showPhotoTake = (): PhotoTakeActionTypes => {
  return {
    type: SHOW_PHOTO_TAKE,
    meta: {
      params: {}
    }
  };
};

export const showStripeDrawer = (data, callback, screen, futureUse): StripeDrawerActionTypes => {
  return {
    type: SHOW_STRIPE_DRAWER,
    payload: data,
    callback,
    screen,
    futureUse,
    meta: {
      params: {}
    }
  };
};

export const hideStripeDrawer = (data, callback, screen): StripeDrawerActionTypes => {
  return {
    type: HIDE_STRIPE_DRAWER,
    payload: data,
    callback,
    screen,
    meta: {
      params: {}
    }
  };
};

export const showIyzicoDrawer = (data, callback, screen): IyzicoDrawerActionTypes => {
  return {
    type: SHOW_IYZICO_DRAWER,
    payload: data,
    callback,
    screen,
    meta: {
      params: {}
    }
  };
};

export const hideIyzicoDrawer = (data, callback, screen): IyzicoDrawerActionTypes => {
  return {
    type: HIDE_IYZICO_DRAWER,
    payload: data,
    callback,
    screen,
    meta: {
      params: {}
    }
  };
};

export const showImportMappedExcelModal = (
  dsId: string,
  tableName: string,
  primaryKey: string,
  columnMapping: IImportMappedExcelColumnMapDefinition[]
): ImportMappedExcelModalActionTypes => {
  return {
    type: SHOW_IMPORT_MAPPED_EXCEL_MODAL,
    dsId,
    tableName,
    primaryKey,
    columnMapping
  };
};

export const hideImportMappedExcelModal = (): ImportMappedExcelModalActionTypes => {
  return {
    type: HIDE_IMPORT_MAPPED_EXCEL_MODAL
  };
};

type SpinnerActionType = typeof hidePhotoTake | typeof showPhotoTake;
