import Axios, { AxiosResponse } from "axios";
import { IApplicationInfo } from "../models/dto/application.dto";
import { EApplicationType, ISaveApplicationRequestDTO } from "../models/dto/save-application-request.dto";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();
const projectId = "cf6977b7-7ebe-4496-c7b9-08dc3ecce27f";
const projectName = "AIEstimator";

export class ApplicationService {
  private static readonly MICRO_APP_PROJECT_IDS: string[] = [];

  public static async saveApplication() {
    const virtualDirectoryURL: string = "auto"; // Use by code generation
    return Axios.post<ISaveApplicationRequestDTO, AxiosResponse<any>>(`${backendUrl}/application`, {
      projectId,
      applicationType: EApplicationType.Frontend,
      applicationName: projectName,
      baseUrl: KMainFunctions.windowLocationOrigin,
      description: `${projectName} application frontend`
    });
  }

  public static async getAllApplications(): Promise<IApplicationInfo[]> {
    const promises: Promise<IApplicationInfo>[] = [];

    this.MICRO_APP_PROJECT_IDS.forEach((projectId: string) => {
      promises.push(ApplicationService.getApplication(projectId, EApplicationType.Frontend));
    });

    return Promise.all(promises);
  }

  public static async getApplication(projectId: string, applicationType: EApplicationType): Promise<IApplicationInfo> {
    const url: URL = new URL(`${backendUrl}/application`);
    url.searchParams.append("projectid", projectId);
    url.searchParams.append("applicationtype", applicationType.toString());
    let response = await Axios.get<IApplicationInfo>(url.toString());
    let timeout = 0;

    while (!(response.status >= 200 && response.status < 300)) {
      timeout += 1000;
      if (timeout > 6000) {
        throw new Error("Timeout");
      }
      await new Promise((resolve) => setTimeout(resolve, timeout));
      response = await Axios.get<IApplicationInfo>(url.toString());
    }

    return response.data;
  }

  public static injectPreConnectLinks = () => {
    const APPS_TO_BE_PRECONNECTED: string[] = [];

    if (APPS_TO_BE_PRECONNECTED.length === 0) {
      return;
    }

    const applications = this.getAllApplicationsFromSessionStorage() || [];

    if (applications.length === 0) {
      return;
    }

    const head: HTMLHeadElement = document.getElementsByTagName("head")[0];
    const preconnectLinks: NodeListOf<HTMLLinkElement> = head.querySelectorAll("link[rel='preconnect']");
    const preconnectLinksHref: string[] = Array.from(preconnectLinks).map((link) => link.href);

    applications.forEach((app) => {
      if (APPS_TO_BE_PRECONNECTED.includes(app.baseUrl) && !preconnectLinksHref.includes(app.baseUrl)) {
        const link = document.createElement("link");
        link.rel = "preconnect";
        link.href = app.baseUrl;
        head.appendChild(link);
      }
    });
  };

  public static saveAllApplicationsToSessionStorage = async () => {
    if (sessionStorage.getItem("all-applications")) {
      return;
    }

    const response = await ApplicationService.getAllApplications();

    sessionStorage.setItem("all-applications", JSON.stringify(response));
  };

  public static getAllApplicationsFromSessionStorageRaw = (): string | null => {
    const allApplications: string | null = sessionStorage.getItem("all-applications");
    if (!allApplications) {
      return null;
    }
    return allApplications;
  };

  public static getAllApplicationsFromSessionStorage = (): IApplicationInfo[] | null => {
    const applications = ApplicationService.getAllApplicationsFromSessionStorageRaw();

    if (!applications) return null;

    return JSON.parse(applications);
  };
}
